<template>
  <v-card>
    <v-toolbar
      color="white"
      flat
    >
      <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.patient.institutionAndDoctor') }}</v-toolbar-title>

      <v-spacer />


    </v-toolbar>
    <v-row>
      <v-col>
        <institution-list />
      </v-col>

      <v-divider vertical></v-divider>

      <v-col
        cols="12"
        md="6"
      >
        <home-docter-list />
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-card-actions>

      <v-spacer></v-spacer>

      <v-btn
        class="mx-1"
        color="info"
        @click="handleCancel"
      >
        {{ $vuetify.lang.t('$vuetify.common.cancel') }}
      </v-btn>

      <v-btn
        class="mx-1"
        color="primary"
        :disabled="homeDoctorSelected.length <= 0 || institutionSelected.length <= 0"
        @click="handleSubmitClick"
      >
        {{ $vuetify.lang.t('$vuetify.common.submit') }}
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>

  import bus from '@/services/bus-event';

  export default {
    components: {
      InstitutionList: () => import('./institution'),
      HomeDocterList: () => import('./homeDoctor')
    },
    data () {
      return {
        homeDoctorSelected: [],
        institutionSelected: []
      }
    },
    computed: {
    },

    mounted () {
      bus.on('home-doctor-change', this.onHomeDoctorChange);
      bus.on('institution-change', this.onInstitutionChange);
    },

    beforeDestroy () {
      bus.off('home-doctor-change', this.onHomeDoctorChange);
      bus.off('institution-change', this.onInstitutionChange);
    },

    methods: {

      onHomeDoctorChange (data) {
        this.homeDoctorSelected = data
      },

      onInstitutionChange (data) {
        this.institutionSelected = data
      },


      handleSubmitClick () {
        this.$emit( 'submit', { institution: this.institutionSelected[0], homeDoctor: this.homeDoctorSelected[0] } )
      },

      handleCancel () {
        this.$emit('cancel', true)
      }
    }
  }
</script>
